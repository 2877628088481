<template>
    <div  style=" margin: -15px -15px;overflow: hidden">
      <a-spin :spinning="spinning">
      <div class="liveMeet-top" >
        <div class="liveMeet-top-input">
          <div>状态:</div>
          <a-radio-group
              style="margin-left: 10px"
              v-model="radioValue"
              @change="radioBtn"
          >
            <a-radio-button value="">
              全部
            </a-radio-button>
            <a-radio-button value="1">
              已开启
            </a-radio-button>
            <a-radio-button value="0">
              未开启
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="liveMeet-top-input">
          <div>标题:</div>
          <a-input
              v-model="titleData"
              class="input"
              placeholder="请输入" />
        </div>
        <div class="liveMeet-top-input">
          <a-button
              @click="seachBtn"
              type="primary">查询</a-button>
          <a-button
              @click="resetBtn"
              style="margin-left: 10px">重置</a-button>
        </div>
      </div>
      <div class="activity-table">
        <div class="activity-table-btn">
          <a-button
              @click="addBtn"
              type="primary">
            新增
          </a-button>
        </div>
        <a-table
            style="width: 98%;margin: auto"
            :columns="columnsLive"
            :data-source="dataLive"
            :rowKey="record=>record.id"
            :pagination="false">
          <span slot="liveStreamTitle" slot-scope="text, record">
            <div v-for="(item,index) in record.liveMeet">
              <a-tooltip>
                <template slot="title">
                  {{record.liveMeet[index].title}}
                </template>
                <div style="width: 100%;display: block;text-overflow:ellipsis;overflow: hidden;white-space: nowrap">
                {{record.liveMeet[index].title}}
              </div>
              </a-tooltip>
<!--              <div style="width: 100%;display: block;text-overflow:ellipsis;overflow: hidden;white-space: nowrap">-->
<!--                {{record.liveMeet[index].title}}-->
<!--              </div>-->
            </div>
          </span>
<!--          <span slot="answers" slot-scope="text, record">-->
<!--            <span v-for="(item,index) in record.answers">-->
<!--              {{item}}<span v-if="index!== record.answers.length-1"> - </span>-->
<!--            </span>-->
<!--          </span>-->
  <!--        是否开启-->
          <span slot="isOpen" slot-scope="text, record">
            <a-switch
                v-model="record.isOpen === 1 ? true:false"
                checked-children="开"
                un-checked-children="关"
                @click="switchOpen(text === 1? 0 : 1,record.id,record)"
                default-checked />
          </span>

          <span slot="finish" slot-scope="text, record">
          <a @click="lookBtn(record.id)">查看答题记录</a>
        </span>
          <span slot="system" slot-scope="text, record">
          <a
              @click="restBtn(record)"
              style="margin-left: 10px">修改</a>
          <a-popconfirm
              title="是否确认删除?"
              ok-text="是"
              cancel-text="否"
              @confirm="confirmDel(record.id)"
              @cancel="cancel"
          >
            <a style="margin-left: 10px">删除</a>
          </a-popconfirm>
        </span>
        </a-table>
        <div style="width: 100%;height: 50px;">
          <div style="float: right;margin-right: 20px;margin-top: 15px">
            <a-pagination
                :current="page_no"
                @change="paginationBtn"
                :total="tableCount" />
          </div>
        </div>
      </div>
  <!----------------------------对话框-------------------------->
      <a-drawer
          title="新增"
          placement="right"
          :closable="false"
          :visible="addVisible"
          width="600px"
          @close="()=>{this.addVisible = false;
          //  清空
        let form = this.addForm
        form.title = ''
        form.answers = ['']
        form.liveStreamId = [undefined,]
        form.live = undefined
        form.type = 1
        this.live_page_no = 1
        this.doctor_page_no = 1
        this.LiveConferenceList = []
          }"
      >
        <a-form-model
            ref="ruleForm"
            :model="addForm"
            :rules="addRules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="问题" prop="title">
            <a-input
                placeholder="请输入"
                style="width: 300px"
                v-model="addForm.title"/>
          </a-form-model-item>
          <a-form-model-item prop="answers" label="选项">
            <div
                style="display: flex; align-items: center ;flex-wrap: wrap;width: 400px "
                v-for="(item,index) in addForm.answers"
                :key="index">
              <div>
                <a-input
                    style="width: 300px"
                    v-model="addForm.answers[index]"
                    placeholder="请输入"/>
              </div>
              <div v-if="index!==0">
                <a-button
                    type="dashed"
                    @click="deleteIndex(item, index)"
                    style=" color:#CCCCCC; border: #CCCCCC 2px solid;margin-left: 20px;" shape="circle" size="small" >
                  <a-icon type="minus" />
                </a-button>
              </div>
              <div v-if=" index <=0" >
                <a-button
                    @click="clickAddTime(index)"
                    style=" color:#0099FF; border: #0099FF 2px solid;margin-left: 20px;" size="small" shape="circle">
                  <a-icon type="plus" />
                </a-button>
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="类型" prop="type">
            <a-radio-group v-model="addForm.type" @change="radio_group_onChange">
              <a-radio :value="1">
                直播会议
              </a-radio>
              <a-radio :value="2">
                医学会议（九宫格会议）
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="选择会议" prop="live">
            <a-select
                @popupScroll="popupScroll_live"
                @search="select_live"
                show-search
                option-filter-prop="children"
                placeholder="请选择"
                v-model="addForm.live"
                style="width: 325px">
              <a-select-option
                  @click="liveChange(selectLive.id)"
                  v-for="(selectLive,index) in LiveConferenceList"
                  :value="selectLive.id">
                {{selectLive.title}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="liveStreamId" label="直播间">
            <div
                style="display: flex; align-items: center ;flex-wrap: wrap;width: 400px "
                v-for="(item,index) in addForm.liveStreamId"
                :key="index">
              <div>
                <a-select
                    v-model="addForm.liveStreamId[index]"
                    show-search
                    style="width: 300px"
                    placeholder="请选择"
                    option-filter-prop="children"
                    @change="selectAddChange"
                >
                  <a-select-option
                      v-for="(live,index) in liveRoom"
                      :value="live.id">
                   ({{ live.id }}) {{ live.title }}
                  </a-select-option>
                </a-select>
              </div>
              <div v-if="index!==0">
                <a-button
                    type="dashed"
                    @click="deleteLiveIndex(item, index)"
                    style=" color:#CCCCCC; border: #CCCCCC 2px solid;margin-left: 20px;" shape="circle" size="small" >
                  <a-icon type="minus" />
                </a-button>
              </div>
              <div v-if=" index <=0" >
                <a-button
                    @click="clickAddLiveTime(index)"
                    style=" color:#0099FF; border: #0099FF 2px solid;margin-left: 20px;" size="small" shape="circle">
                  <a-icon type="plus" />
                </a-button>
              </div>
            </div>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'right',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.addVisible = false;
           //  清空
        let form = this.addForm
        form.title = ''
        form.answers = ['']
        form.liveStreamId = [undefined,]
        form.live = undefined
        form.type = 1
        this.live_page_no = 1
        this.doctor_page_no = 1
        this.LiveConferenceList = []
          }">
            取消
          </a-button>
          <a-button type="primary" @click="addSubmit">
            确定
          </a-button>
        </div>
      </a-drawer>
      <a-drawer
          title="修改"
          placement="right"
          :closable="false"
          :visible="restVisible"
          width="600px"
          @close="()=>{
            this.restVisible = false;
            this.getTableList(this.titleData, this.radioValue, this.page_no, this.page_size)
            //  清空
            let form = this.restForm
            form.title = ''
            form.answers = ['']
            form.liveStreamId = [undefined,]
            form.live = undefined
            form.type = 1
            this.live_page_no = 1
            this.doctor_page_no = 1
            this.LiveConferenceList = []
          }">
        <a-form-model
            ref="ruleRestForm"
            :model="restForm"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="问题">
            <a-input
                placeholder="请输入"
                style="width: 300px"
                v-model="restForm.title"/>
          </a-form-model-item>
          <a-form-model-item  label="选项">
            <div
                style="display: flex; align-items: center ;flex-wrap: wrap;width: 400px "
                v-for="(item,index) in restForm.answers"
                :key="index">
              <div>
                <a-input
                    style="width: 300px"
                    v-model="restForm.answers[index]"
                    placeholder="请输入"/>
              </div>
              <div v-if="index!==0">
                <a-button
                    type="dashed"
                    @click="deleteIndexRest(item, index)"
                    style=" color:#CCCCCC; border: #CCCCCC 2px solid;margin-left: 20px;" shape="circle" size="small" >
                  <a-icon type="minus" />
                </a-button>
              </div>
              <div v-if=" index <=0" >
                <a-button
                    @click="clickRestTime(index)"
                    style=" color:#0099FF; border: #0099FF 2px solid;margin-left: 20px;" size="small" shape="circle">
                  <a-icon type="plus" />
                </a-button>
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="类型">
            <a-radio-group v-model="restForm.type" @change="radio_group_onChange_rest">
              <a-radio :value="1">
                直播会议
              </a-radio>
              <a-radio :value="2">
                医学会议（九宫格会议）
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="选择会议">
            <a-select
                @popupScroll="popupScroll_live_rest"
                @search="select_live_rest"
                show-search
                option-filter-prop="children"
                placeholder="请选择"
                v-model="restForm.live"
                style="width: 325px">
              <a-select-option
                  @click="liveChange_rest(selectLive.id)"
                  v-for="(selectLive,index) in LiveConferenceList"
                  :value="selectLive.id">
                {{selectLive.title}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="直播间">
            <div
                style="display: flex; align-items: center ;flex-wrap: wrap;width: 400px "
                v-for="(item,index) in restForm.liveStreamId"
                :key="index">
              <div>
                <a-select
                    show-search
                    placeholder="请选择"
                    v-model="restForm.liveStreamId[index]"
                    style="width: 300px"
                    option-filter-prop="children"
                    @change="selectRestChange">
                  <a-select-option
                      v-for="(live,index) in liveRoom"
                      :value="live.id">
                    ({{ live.id }}) {{ live.title }}
                  </a-select-option>
                </a-select>
              </div>
              <div v-if="index!==0">
                <a-button
                    type="dashed"
                    @click="deleteLiveIndexRest(item, index)"
                    style=" color:#CCCCCC; border: #CCCCCC 2px solid;margin-left: 20px;" shape="circle" size="small" >
                  <a-icon type="minus" />
                </a-button>
              </div>
              <div v-if=" index <=0" >
                <a-button
                    @click="clickRestLiveTime(index)"
                    style=" color:#0099FF; border: #0099FF 2px solid;margin-left: 20px;" size="small" shape="circle">
                  <a-icon type="plus" />
                </a-button>
              </div>
            </div>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'right',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.restVisible = false;
            //  清空
            let form = this.restForm
            form.title = ''
            form.answers = ['']
            form.liveStreamId = [undefined,]
            form.live = undefined
            form.type = 1
            this.live_page_no = 1
            this.doctor_page_no = 1
            this.LiveConferenceList = []
            this.getTableList(this.titleData, this.radioValue, this.page_no, this.page_size)}">
            取消
          </a-button>
          <a-button type="primary" @click="restSubmit">
            确定
          </a-button>
        </div>
      </a-drawer>
      <a-modal
            title=""
            placement="right"
            :closable="false"
            :visible="visible"
            width="600px"
            @close="closeVis"
            :footer="null"
        >
        <p style="height: 100px">
          <a-icon style="color: red;font-size: 25px;margin-right: 10px" type="close-circle" />
          直播间登录方式必须是【手机号验证码登录】，且完善身份信息为【是】，才可开启问答</p>
          <div
              :style="{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'right',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }"
          >
            <a-button type="primary" @click="closeVis">
              知道了
            </a-button>
          </div>
        </a-modal>
      </a-spin>
    </div>
  </template>

  <script>
  import {delAnsList, getAnswer, getLiveList, getSetLiveList, postAnsList, putAnsList} from "../../service/liveMeet_api";
  import {putAnsList_answers} from "../../service/liveMeet_api2";
  import {CaseLive, getLiveBroadcast} from "../../service/MedicalConference";

  const columnsLive = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width:'5%'
    },{
      title: '问题',
      dataIndex: 'title',
      key: 'title',
      width:'20%'
    },
    // {
    //   title: '选项',
    //   dataIndex: 'answers',
    //   key: 'answers',
    //   scopedSlots: { customRender: 'answers' },
    // },
    {
      title: '关联会议室',
      dataIndex: 'liveStreamTitle',
      key: 'liveStreamTitle',
      width:'35%',
      ellipsis: true,
      scopedSlots: { customRender: 'liveStreamTitle' },
    },
    {
      title: '是否开启',
      dataIndex: 'isOpen',
      key: 'isOpen',
      ellipsis: true,
      width: '10%',
      scopedSlots: { customRender: 'isOpen' },
    },
    {
      title: '记录',
      dataIndex: 'finish',
      key: 'finish',
      ellipsis: true,
      width: '10%',
      scopedSlots: { customRender: 'finish' },
    },
    {
      title: '操作',
      dataIndex: 'system',
      key: 'system',
      ellipsis: true,
      width: '12%',
      scopedSlots: { customRender: 'system' },
    },
  ];
  const dataLive = [];
  export default {
    data(){
      return{
        isOpenSwitch:true,
        liveId:'',
        LiveConferenceList:[],
        spinning:false,
        tableCount:null,
        radioValue:'',
        titleData:'',
        addVisible:false,
        restVisible:false,
        columnsLive,
        dataLive,
        // isShow:1,
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
        liveRoom:[],
        addForm:{
          title:'',
          answers:['',],
          liveStreamId:[undefined,],
          type:1,
          live:undefined,
        },
        restForm:{
          title:'',
          answers:['',],
          liveStreamId:[undefined,],
          type:1,
          live:undefined,
        },
        addRules:{
          title: [{ required: true, message: '请输入', trigger: 'blur' }],
          type: [{ required: true, message: '请选择', trigger: 'blur' }],
          answers: [{ required: true, message: '请选择', trigger: 'blur' }],
          live: [{ required: true, message: '请选择', trigger: 'blur' }],
          liveStreamId: [{ required: true, message: '请选择', trigger: 'blur' }],
        },
        listId:'',
        page_no:1,
        page_size:10,
        visible:false,
        showBtn:1,
        isOpen_table:2,
        live_page_no:1,
        doctor_page_no:1,
        selectTitle:'',
      }
    },
    created() {
      this.getTableList()
        this.$store.dispatch('setManageHeaderTitle',"直播问答管理")
    },
    methods:{
      //获取列表
      async getTableList(title,is_open,page_no,page_size) {
        this.spinning = true
        if(title === '' && title === null && title === undefined){
          title = ''
        }
        if(is_open === '' && is_open === null && is_open === undefined){
          is_open = ''
        }
        const response = await getAnswer(title,is_open,page_no,page_size)
        if(response.code === 0){
          this.dataLive = response.data.rows
          this.tableCount = response.data.count
        }else {
          this.$message.warning("出错，请联系管理员",response.message)
        }
        this.spinning = false
      },
      //新增--选项
      deleteIndex(item, index){
        this.addForm.answers.splice(index,1)
      },
      clickAddTime(index){
        let ifTrue = true
        this.addForm.answers.forEach(item => {
          if (item[index] === undefined) {
            this.$message.warning('请输入选项！')
            ifTrue = false
          }
        })
        if (ifTrue) {
          this.addForm.answers.push("")
        }
      },
      //新增--直播间
      deleteLiveIndex(item, index){
        this.addForm.liveStreamId.splice(index,1)
      },
      clickAddLiveTime(index){
        let ifTrue = true
        this.addForm.liveStreamId.forEach(item => {
          if (item[index] === null) {
            this.$message.warning('请选择直播间！')
            ifTrue = false
          }
        })
        if (ifTrue) {
          this.addForm.liveStreamId.push("")
        }
      },
      selectAddChange(value){

      },

      //修改--选项
      deleteIndexRest(item, index){
        this.restForm.answers.splice(index,1)
      },
      clickRestTime(index){
        let ifTrue = true
        this.restForm.answers.forEach(item => {
          if (item[index] === null) {
            this.$message.warning('请输入选项！')
            ifTrue = false
          }
        })
        if (ifTrue) {
          this.restForm.answers.push("")
        }
      },
      //修改--直播间
      deleteLiveIndexRest(item, index){
        this.restForm.liveStreamId.splice(index,1)
      },
      clickRestLiveTime(index){
        let ifTrue = true
        this.restForm.liveStreamId.forEach(item => {
          if (item[index] === null) {
            this.$message.warning('请选择直播间！')
            ifTrue = false
          }
        })
        if (ifTrue) {
          this.restForm.liveStreamId.push("")
        }
      },
      selectRestChange(value){
      },

      seachBtn(){
        this.getTableList(this.titleData,this.radioValue)
      },
      resetBtn(){
        this.titleData = ''
        this.radioValue = ''
        this.getTableList(this.titleData,this.radioValue)
      },
      radioBtn(){
        this.page_no = 1
        this.getTableList(this.titleData,this.radioValue)
      },

      getSwitchVal(isShow,id,row){
        // 判断接口中的会议列表
        for (let i in row.liveMeet) {
          if (row.liveMeet[i].loginType === 'phone_code' && row.liveMeet[i].isNeedPerfectInfo === 1 || isShow === 0) {
            this.isOpenSwitch = true
          } else {
            this.isOpenSwitch = false
            break;
          }
        }
      },
      async switchOpen(isShow,id,row) {
        if(row.isOpen !== 0){
          await this.getSwitchVal(isShow,id,row)
          if(this.isOpenSwitch === true && (isShow===0 || isShow===1)){
            const data = {
              is_open:isShow
            }
            const response = await putAnsList_answers(id, data)
            if (response.code === 0) {
              this.$message.success('修改成功！')
              // //恢复1
              // this.showBtn = 1
              await this.getTableList(this.titleData, this.radioValue, this.page_no, this.page_size)
            } else {
              this.$message.warning("失败"+response.message)
            }
          }else {
            //打开警告弹窗
            if(isShow === 1){
              this.visible = true
            }
          }
        }else {
          this.$message.warning("已开启过，不能再次开启！")
        }
      },
      async closeVis() {
        this.visible = false
        await this.getTableList(this.titleData, this.radioValue, this.page_no, this.page_size)
      },
      async confirmDel(id) {
        const response = await delAnsList(id)
        if (response.code === 0) {
          this.$message.success('删除成功！')
          await this.getTableList(this.titleData, this.radioValue, this.page_no, this.page_size)
        } else {
          this.$message.warning("出错，请联系管理员", response.message)
        }
      },
      cancel(){

      },
      addSubmit(){
        this.$refs.ruleForm.validate(async valid => {
          if (valid) {
            let form = this.addForm
            let data = {
              title:form.title,
              answers:form.answers,
              liveStreamId:form.liveStreamId,
              isOpen:2,
            }
            const response = await postAnsList(data)
            if(response.code === 0){
              this.$refs.ruleForm.resetFields();
              this.$message.success('添加成功！')
              this.addVisible = false
              //  清空
              let form = this.addForm
              form.title = ''
              form.answers = ['']
              form.liveStreamId = [undefined,]
              form.live = undefined
              form.type = 1
              this.LiveConferenceList = []
              this.live_page_no = 1
              this.doctor_page_no = 1
              await this.getTableList()
            }else {
              this.$message.warning("出错，请联系管理员",response.message)
            }
          } else {
            this.$message.warning("有空，请填写！")
            return false;
          }
        });
      },
      restBtn(row){
        this.Live_Conference_List()
      //  列表id
        this.listId = row.id
        const form = this.restForm
        form.title = row.title
        form.answers = row.answers
        if(row.liveMeet.length !== 0){
          form.live = row.liveMeet[0].liveMettingId
          //设置初始直播间可以选择
          this.liveId = form.live
          this.Live_Conference_room_List()
        }
        form.liveStreamId = row.liveStreamId

        this.restVisible = true
      },
      async restSubmit() {
        let form = this.restForm
        let data = {
          title:form.title,
          answers:form.answers,
          liveStreamId:form.liveStreamId,
        }
        const response = await putAnsList(this.listId,data)
        if (response.code === 0) {
          this.$message.success('修改成功！')
          //  清空
          let form = this.restForm
          form.title = ''
          form.answers = ['']
          form.liveStreamId = [undefined,]
          form.live = undefined
          form.type = 1
          this.live_page_no = 1
          this.doctor_page_no = 1
          this.LiveConferenceList = []
          await this.getTableList(this.titleData,this.radioValue,this.page_no,this.page_size)
        } else {
          this.$message.warning("出错，请联系管理员", response.message)
        }
        this.restVisible = false
      },
      lookBtn(id){
        this.$router.push({path:'/liveliveAnswerRecord',query:{id:id}})
      },
      paginationBtn(page_no,page_size){
        this.page_no = page_no
        this.page_size = page_size
        this.getTableList(this.titleData,this.radioValue,page_no,page_size)
      },
      // //获取会议列表
      // async getMeetList() {
      //   const response = await getSetLiveList('','99999')
      //   if (response.code === 0) {
      //     this.liveRoom = response.data.rows
      //   } else {
      //     this.$message.warning("出错，请联系管理员", response.message)
      //   }
      // },
      //新增对话框
      addBtn(){
        this.addVisible = true
        // this.getMeetList()
        this.Live_Conference_List()
      },
      radio_group_onChange_rest(e){
        this.live_page_no=1
        this.doctor_page_no=1
        this.LiveConferenceList = []
        this.restForm.type = e.target.value
        if(e.target.value === 1){
          this.Live_Conference_List()
        }if(e.target.value === 2){
          this.getCaseLiveList()
        }
        //  清空选中的会议id
        this.liveId = ''
        this.restForm.live = undefined
        this.restForm.liveStreamId = [undefined]
        this.liveRoom = []
      },
      liveChange(id){
        this.liveId = id
        let type = this.addForm.type
        if(type === 1){
          this.Live_Conference_room_List()
        }
        if(type === 2){
          this.getCaseLiveList_room_list()
        }
      },
      liveChange_rest(id){
        this.liveId = id
        let type = this.restForm.type
        this.restForm.liveStreamId = [undefined]
        if(type === 1){
          this.Live_Conference_room_List()
        }
        if(type === 2){
          this.getCaseLiveList_room_list()
        }
      },
      //滚动条监听
      popupScroll_live(e){
        const {target} = e
        const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
        const clientHeight = target.clientHeight //滑动最低高度
        if(scrollHeight < clientHeight + 2){
          this.live_page_no++
          this.doctor_page_no++
          let type = this.addForm.type
          if(type === 1){
            this.Live_Conference_List(this.live_page_no,this.selectTitle)
          }if(type === 2){
            this.getCaseLiveList(this.doctor_page_no,this.selectTitle)
          }
        }
      },
      //鼠标搜索
      select_live(value){
        this.LiveConferenceList = []
        this.selectTitle = value

        let type = this.addForm.type
        if(type === 1){
          this.Live_Conference_List('',value)
        }if(type === 2){
          this.getCaseLiveList('',value)
        }
      },
      //滚动条监听
      popupScroll_live_rest(e){
        const {target} = e
        const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
        const clientHeight = target.clientHeight //滑动最低高度
        if(scrollHeight < clientHeight + 2){
          this.live_page_no++
          this.doctor_page_no++
          let type = this.restForm.type
          if(type === 1){
            this.Live_Conference_List(this.live_page_no,this.selectTitle)
          }if(type === 2){
            this.getCaseLiveList(this.doctor_page_no,this.selectTitle)
          }
        }
      },
      //鼠标搜索
      select_live_rest(value){
        this.LiveConferenceList = []
        this.selectTitle = value

        let type = this.restForm.type
        if(type === 1){
          this.Live_Conference_List('',value)
        }if(type === 2){
          this.getCaseLiveList('',value)
        }
      },
      //获取直播会议列表
      async Live_Conference_List(page_no,value) {
        const response = await getLiveList(page_no,10,value)
        if(response.code === 0){
          // this.LiveConferenceList = response.data.rows
          let list  = response.data.rows
          for (let i=0;i<list.length;i++){
            this.LiveConferenceList.push({
              id: list[i].id,
              title: list[i].title,
            })
          }
        }else {
          this.$message.warning("失败，请联系管理员"+response.message)
        }
      },

      //获取医学会议列表
      async getCaseLiveList(page_no,value) {
        const response = await CaseLive(page_no,10,'',value)
        if(response.code === 0){
          // this.LiveConferenceList = response.data.rows
          let list  = response.data.rows
          for (let i=0;i<list.length;i++){
            this.LiveConferenceList.push({
              id: list[i].id,
              title: list[i].title,
            })
          }
        }else {
          this.$message.warning("失败!",response.message)
        }
      },

      // 获取直播间列表
      async Live_Conference_room_List() {
        //直播会议列表
        let live_metting_id =  this.liveId
        let data = {
          liveMettingId:live_metting_id,
          page_size:9999,
        }
        const response = await getSetLiveList(data)
        if(response.code === 0){
          this.liveRoom = response.data.rows
        }else {
          this.$message.warning("出错，请联系管理员！"+response.message)
        }
      },

      //获取医学会议直播间列表
      async getCaseLiveList_room_list() {
        //医学会议列表id
        let conventionId = this.liveId
        const response = await getLiveBroadcast(conventionId,'',999)
        if (response.code === 0) {
          this.liveRoom = response.data.rows
        } else {
          this.$message.warning("失败"+response.message)
        }
      },

      radio_group_onChange(e){
        this.live_page_no=1
        this.doctor_page_no=1
        this.LiveConferenceList = []
        this.addForm.type = e.target.value
        if(e.target.value === 1){
          this.Live_Conference_List()
        }if(e.target.value === 2){
          this.getCaseLiveList()
        }
        //  清空选中的会议id
        this.liveId = ''
        this.addForm.live = undefined
        this.liveRoom = []
        this.addForm.liveStreamId = [undefined]
      },
    }
  }
  </script>

  <style lang="scss" scoped>
  .liveMeet-top{
    width: 100%;
    height: 85px;
    border-bottom: 15px solid #f0f2f5;
    display: flex;
    justify-content: space-between;
  .liveMeet-top-input{
    display: flex;
    justify-content: center;
    width: 33%;
    height: 50px;
    line-height: 30px;
    padding-top: 20px;
  .input{
    width: 70%;
    margin-left: 10px;
  }
  }
  }
  .activity-table{
    width: 100%;
    min-height: 600px;
    .activity-table-btn{
      width: 100%;
      margin-left: 20px;
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }
  </style>
